import React, { useEffect, useState } from "react";
import "./styles/dashboard.css";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const EditUser = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [roles, setRoles] = useState([]);

  useEffect(() => {

    fetch(`${process.env.REACT_APP_API_URL}/suggests/getuserroles`, {


    method: "post",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    body: [],
  }).then(async (res) => {
    let resp = await res.json();

    if (resp.list) {
    setRoles(resp.list);
    }

    

  });

  document.getElementById('number').value = localStorage.getItem('number');
  document.getElementById('countryCode').value = localStorage.getItem('countryCode');
  document.getElementById('email').value = localStorage.getItem('email');
  document.getElementById('name').value = localStorage.getItem('name');
  

  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    const data = new URLSearchParams();
    data.append("user_id", id);
    for (const pair of new FormData(document.getElementById("addUserForm"))) {
      data.append(pair[0], pair[1]);
    }

    console.log(data);

    fetch(`${process.env.REACT_APP_API_URL}/user/adduser`, {


    method: "post",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    body: data,
  }).then(async (res) => {
    let resp = await res.json();

    console.log(resp);

    if (resp.message === "Edited") {
      toast.success("User updated");
      navigate("/users");
    } else {
      toast.error(resp.message);
    }
  });








  }

  return (
    <div
      style={{ margin: "3rem 2rem", padding: "1rem", backgroundColor: "white" }}
      className="card shadow border-0"
    >
      <form class="row g-3" onSubmit={handleSubmit} id="addUserForm">
        <div class="col-12">
          <label for="inputPassword4" class="form-label">
            Name
          </label>

          <input
            type="text"
            class="form-control shadow-none"
            name="user_name"
            placeholder="Enter user name"
            id="name"
          />
        </div>
        <div class="col-12">
          <label for="inputAddress" class="form-label">
            Email
          </label>
          <input
            type="email"
            class="form-control shadow-none"
            name="email"
            id="email"
            placeholder="Enter user email"
          />
        </div>
        <div class="col-12">
          <label for="inputAddress" class="form-label">
            Password
          </label>
          <input
            type="password"
            class="form-control shadow-none"
            name="password"
            id="password"
            placeholder="Enter user password"
          />
        </div>

        <div class="col-sm-2">
          <label for="inputCity" class="form-label">
            Country Code
          </label>
          <input
            type="text"
            class="form-control shadow-none"
            name="country_code"
            id="countryCode"
            placeholder="+91"
          />
        </div>

        <div class="col-sm-10">
          <label for="inputZip" class="form-label">
            Mobile Number
          </label>
          <input
            type="text"
            class="form-control shadow-none"
            name="mobile_no"
            id="number"
            placeholder="Enter user number"
          />
        </div>

        <div class="col-md-6">
          <label for="inputAddress2" class="form-label">
            Role
          </label>



          {/* <input
            type="text"
            class="form-control shadow-none"
            name="role_fk"
            id="inputAddress2"
            placeholder="Enter user role"
          /> */}

<select id="inputState" class="form-select shadow-none" name="role_fk">

  <option value="">Choose...</option>
      
      {
        roles.length !== 0 ? (

          roles.map((role)=>{

            if (localStorage.getItem("role") === role.user_role_name) {

              return <option selected value={role.user_role_id}>{role.user_role_name}</option>

            }
            

            return <option value={role.user_role_id}>{role.user_role_name}</option>

          })

        ) : (<option value={''}>...</option>)
      }
      
    </select>



        </div>

        <div class="col-md-6">
          <label for="inputAddress2" class="form-label">
            Status
          </label>
          
          <select id="inputState" class="form-select shadow-none" name="status">

            <option value="">Choose...</option>

          {
              localStorage.getItem("status") === '1' ? ( <option value="1" selected>Active</option>):
              ( <option value="1">Active</option>)
            }

{
              localStorage.getItem("status") === '0' ? ( <option value="0" selected>Inactive</option>):
              ( <option value="0">Inactive</option>)
            }
      
     


      
    </select>

        </div>

        <div class="col-12 mt-8">
        <button
                        class="btn d-inline-flex btn-sm btn-neutral mx-1"
                        
                        onClick={() => navigate("/users")}
                      >
                        <span class=" pe-2">
                          <i class="bi bi-arrow-left"></i>
                        </span>
                        <span>Back</span>
                      </button>
          <button type="submit" class="btn btn-primary btn-sm mx-1">
            Edit User
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditUser;
