
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import { Toaster } from "react-hot-toast";
import Dashboard from "./Dashboard";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import Bookings from "./Bookings";
import AddBooking from "./AddBooking";
import EditBooking from "./EditBooking";
import Topagents from "./Topagents";


function App() {
  return (
    <div className="App">

      <Router>
        <Routes>
          <Route path="/" element={<Login/>} />
          <Route path="/users" element={<Dashboard/>} />
          <Route path="/user/add" element={<AddUser/>} />
          <Route path="/user/edit/:id" element={<EditUser/>} />
          <Route path="/bookings" element={<Bookings/>} />
          <Route path="/bookings/add" element={<AddBooking/>} />
          <Route path="/bookings/topagents" element={<Topagents/>} />
          <Route path="/bookings/edit/:id" element={<EditBooking/>} />
        </Routes>
        <Toaster/>
      </Router>
      
      
    </div>
  );
}

export default App;
