import './styles/login.css'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import img from './WhatsApp Image 2024-05-10 at 2.49.27 PM.jpeg';
import React, { useState, useEffect } from 'react';

const Login = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  useEffect(() => {
    // Check if there are saved credentials in localStorage
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');


    setEmail(savedEmail);
    setPassword(savedPassword);
    
  }, []);

    function handleSubmit(e) {

        e.preventDefault();


        const data = new URLSearchParams();
        for (const pair of new FormData(document.getElementById("loginForm"))) {
          data.append(pair[0], pair[1]);
        }
    
        fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
          method: "post",
          body: data,
        }).then(async (res) => {
          let resp = await res.json();

          console.log(resp);
          //alert(resp.token)
          if (resp.token) {

            localStorage.setItem("token", resp.token);

            localStorage.setItem('email', document.getElementById('email').value);
            localStorage.setItem('password', document.getElementById('password').value);

            toast.success(resp.response);
            navigate('/bookings');

          } else {

            toast.error(resp.response);

          }
          
         

          
        });









        
    }

  return (
    <div className="login">
        <form id='loginForm' onSubmit={handleSubmit}>
            <img src={img} style={{width:'5em', marginBottom:'2rem'}} alt="" />
            <input type='email' name='email' id='email' placeholder='Email' 
            onChange={(e) => setEmail(e.target.value)} value={email} />
            <input type='password' name='password' id='password' value={password} placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
            <button type='submit' className='btn btn-primary'>Log In</button>
        </form>
    </div>
  )
}

export default Login