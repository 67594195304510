import React, { useEffect, useState } from "react";
import "./styles/dashboard.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import img from "./WhatsApp Image 2024-05-10 at 2.49.27 PM.jpeg";

const Bookings = () => {

  const doc = new jsPDF();

  const navigate = useNavigate();

  const [bookings, setBookings] = useState(null);

  const [times, setTimes] = useState(null);

  const [pickups, setPickups] = useState(null);

  const [dele, setDele] = useState(false);

  let slno  = 0;

  function handlePDF() {
    
   
    
    

    doc.autoTable({ html: '#myTable', theme:'grid' });

doc.save('Booking_Report.pdf')

  }

  function handleSubmit() {

    const data = new URLSearchParams();

    data.append('pickup_date', document.getElementById('date').value);
    data.append('time_id', document.getElementById('time').value);
    data.append('pickup_point_id', document.getElementById('pickup').value);
    
    fetch(`${process.env.REACT_APP_API_URL}/booking/getlist`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: data,
    }).then(async (res) => {
      let resp = await res.json();
      console.log(resp);

      if (resp.message === "OK") {

        if (resp.list.length === 0) {
          toast.error('No data found');
        } else {
          setBookings(resp.list);
        }

      } else {
        toast.error(resp.message);
      }
    });







  }

  function handleSubmit_To_whatsapp()
  {
    const data = new URLSearchParams();

    data.append('pickup_date', document.getElementById('date').value);
    data.append('time_id', document.getElementById('time').value);
    data.append('pickup_point_id', document.getElementById('pickup').value);
    
    fetch(`${process.env.REACT_APP_API_URL}/booking/send_whatsapp`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: data,
    }).then(async (res) => {
      let resp = await res.json();
      console.log(resp);

      if (resp.message === "OK") {

        alert('Message sent');

      } else {
        toast.error(resp.message);
      }
    });

  }

  function formatDate(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1; // Months are zero based
    var year = date.getFullYear();

    // Add leading zeros to day and month if necessary
    if(day < 10) {
        day = '0' + day;
    }
    if(month < 10) {
        month = '0' + month;
    }

    return year + '-' + month + '-' + day;
}

  useEffect(() => {

    fetch(`${process.env.REACT_APP_API_URL}/suggests/getbustimings`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: [],
    }).then(async (res) => {
      let resp = await res.json();
      if (resp.message === "OK") {

        setTimes(resp.list);
        console.log(resp);

      } else {

        toast.error(resp.message);

      }

      
    });


    fetch(`${process.env.REACT_APP_API_URL}/suggests/getpickuplocations`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: [],
    }).then(async (res) => {
      let resp = await res.json();
      if (resp.message === "OK") {

        setPickups(resp.list);
        console.log(resp);

      } else {

        toast.error(resp.message);

      }

      
    });




    if (dele) {
      handleSubmit();
      setDele(false);
    }

    setTimeout(
      () => document.getElementById('date').value = formatDate(new Date()),
      700
    );


   
  }, [dele])
  

  
   

  

  return (
    <>
      <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <div class="h-screen flex-grow-1 overflow-y-lg-auto">
          <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
              <div class="mb-npx">
                <div class="row align-items-center mb-6" >
                  <div class="col-sm-6 col-6 mb-4 mb-sm-0">
                    <img src={img} alt="" style={{ width: "4em" }} />
                  </div>

                  <div class="col-sm-6 col-12 text-sm-end">
                    <div class="mx-n1">

                     

                      <button
                        class="btn d-inline-flex btn-sm btn-neutral border-base mx-1"
                        onClick={()=>{

                          localStorage.removeItem("token");
                          navigate('/');

                        }}
                      >
                        <span class=" pe-2">
                          <i class="bi bi-box-arrow-left"></i>
                        </span>
                        <span>Log Out</span>
                      </button>

                      <button
                        class="btn d-inline-flex btn-sm btn-primary mx-1"
                        onClick={()=>navigate('/users')}
                      >
                        <span class=" pe-2">
                          <i class="bi bi-people-fill"></i>
                        </span>
                        <span>View Users</span>
                      </button>

                    


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <main class="py-6 bg-surface-secondary">
            <div class="container-fluid">
              <div class="card shadow border-0 mb-7">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "2rem",
                    flexWrap: "wrap",
                    padding: "32px 0 28px",
                    paddingLeft: "24px",
                  }}
                >
                  <div>
                    <span style={{ color: "black" }}>Pickup Date:</span>

                    <input
                      className="form-control form-control-sm"
                      style={{ marginTop: "0.3rem" }}
                      type="date"
                      id="date"
                    />
                  </div>

                  <div>


                    <span style={{ color: "black" }}>Bus Time:</span>

                    <select
                      id="time"
                      class="form-select form-select-sm"
                      style={{ marginTop: "0.3rem" }}
                    >
                      <option selected value={''}>Choose...</option>
                      {
                        times ? (
                          times.map((time)=>{

                           return <option value={time.time_id}>{time.time_name}</option>

                          })
                        ):(
                          <option>...</option>
                        )
                      }
                      
                    </select>


                  </div>


                  <div>


                    <span style={{ color: "black" }}>Pickup:</span>

                    <select
                      id="pickup"
                      class="form-select form-select-sm"
                      style={{ marginTop: "0.3rem" }}
                    >
                      <option selected value={''}>All...</option>
                      {
                        pickups ? (
                          pickups.map((time)=>{

                           return <option value={time.pickup_point_id}>{time.pickup_point_name}</option>

                          })
                        ):(
                          <option>...</option>
                        )
                      }
                      
                    </select>


                  </div>

                  <button
                    className="btn btn-primary btn-sm shadow-none"
                    style={{ alignSelf: "flex-end" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover table-nowrap" id="myTable">
                    <thead class="thead-light">
                      <tr>
                        <th>Sl No</th>
                        <th scope="col">Pax Name</th>
                        <th scope="col">Contact No</th>
                        <th scope="col">Drop Point</th>
                        <th scope="col">Pickup Point</th>
                        <th scope="col">Sub Pickup</th>
                        <th scope="col">Agency Name</th>
                        <th>Remarks</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      
                      bookings ? (
                        
                        bookings.map((booking, user) => {
                          slno++;
                          var telno = "tel:"+booking.pax_contact_no;
                          var user_details = booking.added_user + " on " + booking.added_on + ", "+ booking.time_name;
                          return (
                            <tr>
                              <td>{slno}</td>
                              <td>{booking.pax_name}</td>
                              <td>
                                <a href={telno}>{booking.pax_contact_no}</a>
                              </td>
                              <td>{booking.drop_point_name}</td>
                              <td>
                                {booking.pickup_point_name}
                              </td>
                              <td>
                                {booking.sub_pickup}
                              </td>
                              <td>
                                {booking.agency_name}
                              </td>
                              <td title={user_details}>{booking.remarks}</td>
                              <td class="text-end">
                                <button
                                  onClick={() => {
                                    navigate(`/bookings/edit/${booking.booking_id}`);

                                    localStorage.setItem(
                                      "pax_name",
                                      `${booking.pax_name}`
                                    );

                                    localStorage.setItem(
                                      "pax_number",
                                      `${booking.pax_contact_no}`
                                    );
                                    localStorage.setItem(
                                      "pickup_date",
                                      `${booking.pickup_date}`
                                    );
                                    localStorage.setItem(
                                      "pickup_time",
                                      `${booking.pickup_time}`
                                    );
                                    localStorage.setItem("flight_time", `${booking.flight_time}`);
                                    localStorage.setItem("remarks", `${booking.remarks}`);
                                    localStorage.setItem("agency_name", `${booking.agency_name}`);
                                    localStorage.setItem("pickup_point_name", `${booking.pickup_point_name}`);
                                    localStorage.setItem("sub_pickup", `${booking.sub_pickup}`);
                                    

                                    localStorage.setItem("drop_point_name", `${booking.drop_point_name}`);
                                    localStorage.setItem("bus_time_id", `${booking.bus_time_id}`);
                                  }}
                                  class="btn btn-sm btn-neutral"
                                  style={{ marginRight: "0.5rem" }}
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={()=>{
                                    if(window.confirm('Delete?')===false)
                                    {
                                      return false;
                                    }
                                    const data = new URLSearchParams();

                                    data.append('booking_id', booking.booking_id);
                                    
                                    fetch(`https://testtravel.4cr.in/index.php/booking/deletebooking`, {
                                      method: "post",
                                      headers: {
                                        Authorization: localStorage.getItem("token"),
                                      },
                                      body: data,
                                    }).then(async (res) => {
                                      let resp = await res.json();
                                      console.log(resp);
                                
                                      if (resp.message === "Deleted") {

                                        setDele(true);
                                
                                        toast.success("Booking deleted")
                                
                                      } else {
                                        toast.error(resp.message);
                                      }
                                    });

                                  }}
                                  type="button"
                                  class="btn btn-sm btn-square btn-neutral shadow-none"
                                >
                                  <i class="bi bi-trash"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        
                        <tr>
                          
                            <td colSpan={'8'} >Submit bus time and pickup date to see bookings.</td>
                          
                        </tr>
                        
                      )}
                    </tbody>
                  </table>
                </div>
                <div class="card-footer border-0 py-5">
                  <button
                    class="btn d-inline-flex btn-sm btn-primary mx-1"
                    onClick={() => {
                      navigate("/bookings/add");
                    }}
                  >
                    <span class=" pe-2">
                      <i class="bi bi-plus"></i>
                    </span>
                    <span>Add Booking</span>
                  </button>


                  <button
                    class="btn d-inline-flex btn-sm btn-primary mx-1"
                    onClick={() => {
                      navigate("/bookings/topagents");
                    }}
                  >
                    <span class=" pe-2">
                      <i class="bi bi-graph"></i>
                    </span>
                    <span>Top Agents</span>
                  </button>

                  <button
                        class="btn d-inline-flex btn-sm btn-primary mx-1"
                        onClick={handlePDF}
                      >
                        <span class=" pe-2">
                          <i class="bi bi-download"></i>
                        </span>
                        <span>PDF</span>
                      </button>

                      <button
                        class="btn d-inline-flex btn-sm btn-primary mx-1"
                        onClick={handleSubmit_To_whatsapp}
                      >
                        <span class=" pe-2">
                          <i class="bi bi-download"></i>
                        </span>
                        <span>Send to Whatsapp</span>
                      </button>


                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Bookings;
