import React, { useEffect, useState } from "react";
import "./styles/dashboard.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/suggests/getuserroles`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: [],
    }).then(async (res) => {
      let resp = await res.json();

      if (resp.list) {
        setRoles(resp.list);
      }
    });
  }, []);

  function handleSubmit(e) {

        
    e.preventDefault();

    const data = new URLSearchParams();
    for (const pair of new FormData(document.getElementById("addUserForm"))) {
      data.append(pair[0], pair[1]);
    }

    console.log(data);

    fetch(`${process.env.REACT_APP_API_URL}/user/adduser`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: data,
    }).then(async (res) => {
      let resp = await res.json();

      console.log(resp);

      if (resp.message === "Added") {
        toast.success("User added");
        navigate("/users");
      } else {
        toast.error(resp.message);
      }
    });
  }







  return (
    <div
      style={{ margin: "3rem 2rem", padding: "1rem", backgroundColor: "white" }}
      className="card shadow border-0"
    >
      <form class="row g-3" onSubmit={handleSubmit} id="addUserForm">
        <div class="col-12">
          <label for="inputPassword4" class="form-label">
            Name
          </label>

          <input
            type="text"
            class="form-control shadow-none"
            name="user_name"
            placeholder="Enter user name"
          />
        </div>
        <div class="col-12">
          <label for="inputAddress" class="form-label">
            Email
          </label>
          <input
            type="email"
            class="form-control shadow-none"
            name="email"
            id="inputAddress"
            placeholder="Enter user email"
          />
        </div>
        <div class="col-12">
          <label for="inputAddress" class="form-label">
            Password
          </label>
          <input
            type="password"
            class="form-control shadow-none"
            name="password"
            id="inputAddress"
            placeholder="Enter user password"
          />
        </div>

        <div class="col-sm-2">
          <label for="inputCity" class="form-label">
            Country Code
          </label>
          <input
            type="text"
            class="form-control shadow-none"
            name="country_code"
            id="inputCity"
            placeholder="+91"
          />
        </div>

        <div class="col-sm-10">
          <label for="inputZip" class="form-label">
            Mobile Number
          </label>
          <input
            type="text"
            class="form-control shadow-none"
            name="mobile_no"
            id="inputZip"
            placeholder="Enter user number"
          />
        </div>

        <div class="col-md-6">
          <label for="inputAddress2" class="form-label">
            Role
          </label>

          {/* <input
            type="text"
            class="form-control shadow-none"
            name="role_fk"
            id="inputAddress2"
            placeholder="Enter user role"
          /> */}

          <select
            id="inputState"
            class="form-select shadow-none"
            name="role_fk"
          >
            <option selected value={''}>Choose...</option>
            {roles.length !== 0 ? (
              roles.map((role) => {
                return (
                  <option value={role.user_role_id}>
                    {role.user_role_name}
                  </option>
                );
              })
            ) : (
              <option value={''}>...</option>
            )}
          </select>
        </div>

        <div class="col-md-6">
          <label for="inputAddress2" class="form-label">
            Status
          </label>

          <select id="inputState" class="form-select shadow-none" name="status">
            <option selected value={''}>Choose...</option>
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>

        <div class="col-12 mt-8">
        <button
                        class="btn d-inline-flex btn-sm btn-neutral mx-1"
                        
                        onClick={() => navigate("/users")}
                      >
                        <span class=" pe-2">
                          <i class="bi bi-arrow-left"></i>
                        </span>
                        <span>Back</span>
                      </button>
          <button type="submit" class="btn btn-primary btn-sm mx-1">
            Add User
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUser;
