import React, { useEffect, useState } from "react";
import "./styles/dashboard.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import img from "./WhatsApp Image 2024-05-10 at 2.49.27 PM.jpeg";

const Bookings = () => {

  const doc = new jsPDF();

  const navigate = useNavigate();

  const [bookings, setBookings] = useState(null);

  const [times, setTimes] = useState(null);

  const [pickups, setPickups] = useState(null);

  const [dele, setDele] = useState(false);

  let slno  = 0;

  function handlePDF() {
    
   
    
    

    doc.autoTable({ html: '#myTable', theme:'grid' });

doc.save('Booking_Report.pdf')

  }

  function handleSubmit() {

    const data = new URLSearchParams();

    data.append('from_date', document.getElementById('from_date').value);
    data.append('to_date', document.getElementById('to_date').value);

    data.append('report_type', document.getElementById('report_type').value);


    
    fetch(`${process.env.REACT_APP_API_URL}/booking/top_agencies`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: data,
    }).then(async (res) => {
      let resp = await res.json();
      console.log(resp);

      if (resp.message === "OK") {

        if (resp.list.length === 0) {
          toast.error('No data found');
        } else {
          setBookings(resp.list);
        }

      } else {
        toast.error(resp.message);
      }
    });







  }

  

  function formatDate(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1; // Months are zero based
    var year = date.getFullYear();

    // Add leading zeros to day and month if necessary
    if(day < 10) {
        day = '0' + day;
    }
    if(month < 10) {
        month = '0' + month;
    }

    return year + '-' + month + '-' + day;
}

  useEffect(() => {

    fetch(`${process.env.REACT_APP_API_URL}/suggests/getbustimings`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: [],
    }).then(async (res) => {
      let resp = await res.json();
      if (resp.message === "OK") {

        setTimes(resp.list);
        console.log(resp);

      } else {

        toast.error(resp.message);

      }

      
    });


    fetch(`${process.env.REACT_APP_API_URL}/suggests/getpickuplocations`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: [],
    }).then(async (res) => {
      let resp = await res.json();
      if (resp.message === "OK") {

        setPickups(resp.list);
        console.log(resp);

      } else {

        toast.error(resp.message);

      }

      
    });




    if (dele) {
      handleSubmit();
      setDele(false);
    }

    setTimeout(
      () => document.getElementById('from_date').value = formatDate(new Date()),
      700
    );
    setTimeout(
        () => document.getElementById('to_date').value = formatDate(new Date()),
        700
      );


   
  }, [dele])
  

  
   
  let grandTotal = 0;
  

  return (
    <>
      <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <div class="h-screen flex-grow-1 overflow-y-lg-auto">
          <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
              <div class="mb-npx">
                <div class="row align-items-center mb-6" >
                  <div class="col-sm-6 col-6 mb-4 mb-sm-0">
                    <img src={img} alt="" style={{ width: "4em" }} />
                  </div>

                  <div class="col-sm-6 col-12 text-sm-end">
                    <div class="mx-n1">

                     

                      <button
                        class="btn d-inline-flex btn-sm btn-neutral border-base mx-1"
                        onClick={()=>{

                          localStorage.removeItem("token");
                          navigate('/');

                        }}
                      >
                        <span class=" pe-2">
                          <i class="bi bi-box-arrow-left"></i>
                        </span>
                        <span>Log Out</span>
                      </button>

                      <button
                        class="btn d-inline-flex btn-sm btn-primary mx-1"
                        onClick={()=>navigate('/users')}
                      >
                        <span class=" pe-2">
                          <i class="bi bi-people-fill"></i>
                        </span>
                        <span>View Users</span>
                      </button>

                    


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <main class="py-6 bg-surface-secondary">
            <div class="container-fluid">
              <div class="card shadow border-0 mb-7">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "2rem",
                    flexWrap: "wrap",
                    padding: "32px 0 28px",
                    paddingLeft: "24px",
                  }}
                >
                  <div>
                    <span style={{ color: "black" }}>From Date:</span>

                    <input
                      className="form-control form-control-sm"
                      style={{ marginTop: "0.3rem" }}
                      type="date"
                      id="from_date"
                    />
                  </div>

                  <div>
                    <span style={{ color: "black" }}>To Date:</span>

                    <input
                      className="form-control form-control-sm"
                      style={{ marginTop: "0.3rem" }}
                      type="date"
                      id="to_date"
                    />
                  </div>

                  
                  <div>


                    <span style={{ color: "black" }}>Bus Time:</span>

                    <select
                      id="report_type"
                      class="form-select form-select-sm"
                      style={{ marginTop: "0.3rem" }}
                    >
                      <option  value='1'>Based on Pickup Date</option>
                      <option  value='2'>Based on Booking Date</option>
                      
                      
                    </select>


                  </div>

                  

                  <button
                    className="btn btn-primary btn-sm shadow-none"
                    style={{ alignSelf: "flex-end" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover table-nowrap" id="myTable">
                    <thead class="thead-light">
                      <tr>
                        <th>Sl No</th>
                        <th scope="col">Agency Name</th>
                        <th scope="col">Booking Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                       
                      bookings ? (
                        
                        bookings.map((booking, user) => {
                          slno++;
                          grandTotal += parseInt(booking.total); // Accumulate the total
                          return (
                            <tr>
                              <td>{slno}</td>
                              <td>{booking.agency_name}</td>
                              
                              <td>{booking.total}</td>
                              
                            </tr>
                          );
                        })
                      ) : (
                        
                        <tr>
                          
                            <td colSpan={'3'} >Submit bus time and pickup date to see bookings.</td>
                          
                        </tr>
                        
                      )}

                      {/* Grand total row */}
        {bookings && bookings.length > 0 && (
          <tr>
            <td colSpan="2" className="text-right"><strong>Grand Total</strong></td>
            <td><strong>{grandTotal}</strong></td>
          </tr>
        )}
                    </tbody>
                  </table>
                </div>
                <div class="card-footer border-0 py-5">
                  <button
                    class="btn d-inline-flex btn-sm btn-primary mx-1"
                    onClick={() => {
                      navigate("/bookings");
                    }}
                  >
                    <span class=" pe-2">
                      <i class="bi bi-plus"></i>
                    </span>
                    <span>Back</span>
                  </button>

                  

                      


                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Bookings;
