import React, { useEffect, useState } from "react";
import "./styles/dashboard.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import img from './WhatsApp Image 2024-05-10 at 2.49.27 PM.jpeg';

const Dashboard = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user/getlist`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: [],
    }).then(async (res) => {
      let resp = await res.json();
      console.log(resp);

      if (resp.message === "OK") {
        setUsers(resp.list);
      } else {
        toast.error(resp.message);
      }
    });
  }, []);

  return (
    <>
      <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <div class="h-screen flex-grow-1 overflow-y-lg-auto">
          <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
              <div class="mb-npx">
                <div class="row align-items-center mb-6">
                  <div class="col-sm-6 col-6 mb-4 mb-sm-0">
                    
                      <img src={img} alt="" style={{width:'4em'}} />
                    
                  </div>

                  <div class="col-sm-6 col-6 text-sm-end" style={{textAlign:'right'}}>
                    <div class="mx-n1">

                     
                    <button
                        class="btn d-inline-flex btn-sm btn-neutral mx-1"
                        
                        onClick={() => navigate("/bookings")}
                      >
                        <span class=" pe-2">
                          <i class="bi bi-arrow-left"></i>
                        </span>
                        <span>Back</span>
                      </button>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <main class="py-6 bg-surface-secondary">
            <div class="container-fluid">
              <div class="card shadow border-0 mb-7">
                <div class="card-header">
                  <h5 class="mb-0">Users</h5>
                </div>
                <div class="table-responsive">
                  <table class="table table-hover table-nowrap">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Mob Number</th>
                        <th scope="col">Role</th>
                        <th scope="col">Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {users != []
                        ? users.map((user) => {
                            return (
                              <tr>
                                <td>{user.user_name}</td>
                                <td>{user.email}</td>
                                <td>
                                  {"(" + user.country_code + ")"}{" "}
                                  {user.mobile_no}
                                </td>
                                <td>{user.user_role_name}</td>
                                <td>
                                  <span class="badge badge-lg badge-dot">
                                    {user.status === '0' ? (
                                      <>
                                        
                                        <i
                                          style={{ backgroundColor: "gray" }}
                                        ></i>
                                        {'Inactive'}
                                      </>
                                    ) : (
                                      <>
                                        
                                        <i className="bg-success"></i>
                                        {'Active'}
                                      </>
                                    )}
                                  </span>
                                </td>
                                <td class="text-end">
                                  < button
                                    onClick={()=>{
                                      navigate(`/user/edit/${user.user_id}`)

                                      localStorage.setItem("name", `${user.user_name}`);
                                  
                                      localStorage.setItem("email", `${user.email}`);
                                      localStorage.setItem("number", `${user.mobile_no}`);
                                      localStorage.setItem("countryCode", `${user.country_code}`);
                                      localStorage.setItem("role", `${user.user_role_name}`);
                                      localStorage.setItem("status", `${user.status}`);
                                  
                                    }
                                  }
                                    class="btn btn-sm btn-neutral"
                                    style={{ marginRight: "0.5rem" }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-square btn-neutral text-danger-hover"
                                  >
                                    <i class="bi bi-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
                <div class="card-footer border-0 py-5">
                  <button
                    class="btn d-inline-flex btn-sm btn-primary mx-1"
                    onClick={() => {
                      navigate("/user/add");
                    }}
                  >
                    <span class=" pe-2">
                      <i class="bi bi-plus"></i>
                    </span>
                    <span>Add User</span>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
